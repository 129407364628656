<script>
import Layout from "../../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";


export default {
  page: {
    title: "Modifier un type de contrat",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Modifier un type de contrat",
      suppliers: [],
      users: [],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      types: [],
      typeForm: {
        uuid: "",
        name: "",
        description: "",
        type: "",
      },
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "javascript:;",
        },
        {
          text: "Contrats",
          href: "javascript:;",
        },
        {
          text :"Restauration",
          href : "settings/prestations/"
        },
        {
          text: "Types de repas",
          href: "settings/prestations/restauration/typeRepas",
        },
        {
          text: "Modification",
          active: true,
        },
      ],
    };
  },

  validations: {
    typeForm: {
      name: { required, maxLength: maxLength(64) },
    },
  },

  mounted() {
      this.fetchTypeData();
  },

  methods: {
    fetchTypeData() {
      this.$http
        .post("/base/restauration/typeRepas/getSingleTypeRepasDetails", {
          uuid: this.$route.params.uid,
        })
        .then((res) => {
          var status = res.data.original.status;
          var type = res.data.original.data;
          switch (status) {
            case 200:
                this.typeForm.uuid = type.uuid;
                this.typeForm.name = type.designation;
              break;

            case 404:
                this.$toast.warning(res.data.original.msg);
              break;

            case 500:
                this.$toast.error(res.data.original.msg);
              break;
          }
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http
        .post("/base/restauration/typeRepas/update", this.typeForm)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              router.push({ name: "base.settings.prestations.restauration.typeRepas.index" });
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              break;

              case 404:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      id="projectname"
                      v-model="typeForm.name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.typeForm.name.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.typeForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.typeForm.name.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
